@import "../scss/reset";
@import "../scss/vars";
@import "../scss/mixins";

$font-size: 13px;
$gutter-size: 20px;

.WechaletRangePicker_Container {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  position: relative;
  display: block;
  width: 100%;
  * {
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }
  .DayPicker-wrapper {
    position: relative;
  }
  .WechaletRangePicker {
    @media only screen and (max-width: 767px) {
      width: 100%;
      position: static;
      margin-top: 10px;
      background-color: #fff;
      border: 1px solid #f5f5f5;
      border-radius: 4px;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
      -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
      .DayPicker-Months {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .DayPicker-Month {
          width: 300px;
          max-width: 100%;
          margin-right: 0px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.WechaletRangePicker {
  box-sizing: border-box;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  right: 0px;
  z-index: 100000;
  width: $calendar-width;

  .DayPicker-NavBar {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    width: calc(100% - 0px);
    &--next,
    &--prev {
      cursor: pointer;
      background: none;
      border: none;
      display: block;
      color: $base-color;
      padding: 0;
      z-index: 9999;
      height: 20px;
      &:hover {
        color: $brand-color;
      }
    }
    &--next {
      float: right;
    }
    &--prev {
      float: left;
    }
    .ChevronIcon-left,
    .ChevronIcon-right {
      transform: rotate(180deg);
    }
  }

  .DayPicker-Months {
    width: calc(100% - 0px);
    float: left;
    .DayPicker-Month {
      width: calc(100% * 1 / 2 - 10px);
      float: left;
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
      .DayPicker-Caption {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: $base-color;
      }
    }
  }

  & .DayPicker-Weekdays {
    margin-top: 12px;
  }

  & .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .DayPicker-Weekday {
    color: inherit;
    white-space: nowrap;
    cursor: pointer;
    font-size: $font-size;
    font-weight: normal;
    padding: 12px;
    flex: 1 1 32px;
    text-align: center;
    color: $base-color;
    abbr {
      text-decoration: none;
    }
  }

  & .DayPicker-Body {
    .DayPicker-Week {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .DayPicker-Day {
    font-size: $font-size;
    font-weight: normal;
    display: block;
    flex: 1 1 32px;
    text-align: center;
    padding: 10px 12px;
    color: $base-color;
    cursor: pointer;
    &:hover:not(.DayPicker-Day--start):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
      background-color: $white-color !important;
      color: currentcolor !important;
    }
  }

  .DayPicker-Day--disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    font-family: "Poppins", sans-serif;
    background-color: #ffffff;
    color: $base-color;
  }

  .DayPicker-Footer {
    font-family: "Poppins", sans-serif;
    width: 100%;
    padding: 15px 0px 0px;
    border-top: 0px;
    display: flex;
    justify-content: flex-end;
    .DayPicker-TodayButton {
      font-family: "Poppins", sans-serif;
      background-color: transparent;
      border: 0px;
      font-size: 14px;
      font-weight: normal;
      color: $brand-color;
      cursor: pointer;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
    background-color: $white-color !important;
    color: currentcolor !important;
  }

  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $brand-color;
    color: white;
  }

  .DayPicker-Day--start:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
  .DayPicker-Day--end:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: darken($brand-color, 0.3);
  }
}

.WechaletRangePicker_ToggleButton {
  cursor: pointer;
  margin: 0;
  outline: none;
  border: none;
  text-transform: capitalize;
  position: relative;
  font-size: 15px;
  font-weight: normal;
  transition: 0.3s ease-in-out;
  transition-property: color, background-color, border-color;
  padding: 16px 16px;
  background-color: $white-color;
  border-color: $white-color;
  color: $base-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.WechaletRangePicker_ToggleButton {
  width: 100%;
  text-align: left;
  border-radius: 2px;
  padding-left: 45px;
  .CalendarIcon {
    position: absolute;
    top: 50%;
    left: 15px;
    width: 24px;
    height: 24px;
    color: $brand-color;
    transform: translateY(-50%);
  }
  .ChevronIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    height: 20px;
    width: 20px;
    transform: translateY(-50%);
    transition: 0.3s ease-in-out;
    transition-property: transform;
    transform-origin: center;

    &.rotate {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
