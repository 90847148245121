@import 'styles/abstracts';
@import '../../styles/calendar-variables.module';

.tile {
  flex: 1;
  height: 32px;
}

.primary {
  background-color: $green;
}

.secondary {
  background-color: $gray-700;
}

.danger {
  background-color: $red;
}

.tileStart {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 44px;
  border-top-left-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: calc($day-md-width/2);
  margin-right: -1px;
  position: relative;
  @include max-screen-md {
    margin-left: calc($day-sm-width/ 2);
  }
}

.tileCenter {
  margin-left: -1px;
  margin-right: -1px;
}

.tileEnd {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  margin-left: -1px;
  max-width: calc($day-md-width / 2);

  @include max-screen-md {
    width: calc($day-sm-width / 2);
  }
}

.tileFiller {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -1px;
  margin-right: 5px;

  width: calc($day-md-width / 2);

  @include max-screen-md {
    width: calc($day-sm-width / 2);
  }
}

.tileCross {
  border-bottom-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  border-top-left-radius: 44px;
  border-top-left-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  margin-right: -1px;
  position: relative;
}
