@mixin screen($min-width) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}

@mixin max-screen($max-width) {
  @media screen and (max-width: ($max-width - 0.02)) {
    @content;
  }
}

@mixin screen-xxs {
  @include screen($screen-xxs) {
    @content;
  }
}

@mixin max-screen-xs {
  @include max-screen($screen-xs) {
    @content;
  }
}

@mixin screen-xs {
  @include screen($screen-xs) {
    @content;
  }
}

@mixin max-screen-sm {
  @include max-screen($screen-sm) {
    @content;
  }
}

@mixin screen-sm {
  @include screen($screen-sm) {
    @content;
  }
}

@mixin max-screen-md {
  @include max-screen($screen-md) {
    @content;
  }
}

@mixin screen-md {
  @include screen($screen-md) {
    @content;
  }
}

@mixin max-screen-lg {
  @include max-screen($screen-lg) {
    @content;
  }
}

@mixin screen-lg {
  @include screen($screen-lg) {
    @content;
  }
}

@mixin max-screen-xl {
  @include max-screen($screen-xl) {
    @content;
  }
}

@mixin screen-xl {
  @include screen($screen-xl) {
    @content;
  }
}

@mixin max-screen-xxl {
  @include max-screen($screen-xxl) {
    @content;
  }
}

@mixin screen-xxl {
  @include screen($screen-xxl) {
    @content;
  }
}
