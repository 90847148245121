@import 'styles/abstracts';
@import '../../styles/calendar-variables.module';

.container {
  background-color: $white;
  border-color: $border-color-primary;
  border-style: solid;
  border-width: 0 1px 1px;
  height: $day-height;
  left: -1px;
  position: absolute;
  width: $listing-name-width-md;
  @include max-screen-md {
    width: $listing-name-width-sm;
  }
}

.listingTitle {
  color: $black;
  display: block;
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  padding: 0 0.7rem;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;

  &:hover {
    color: $green;
  }
}

.listingCover {
  height: 60px;
  margin-right: 1rem;
  max-width: 80px;
  object-fit: cover;
  transition: transform 0.3s, opacity 0.3s;

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }
}

.listingLink {
  align-items: center;
  display: flex;
  height: 60px;
}

.thumbnailContainer {
  position: relative;
}

.eyeIcon {
  bottom: 0;
  fill: rgba(0, 0, 0, 0.44);
  left: 30%;
  margin: auto;
  pointer-events: none;
  position: absolute;
  top: 0;
}
