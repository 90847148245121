@import "../../scss/reset";
@import "../../scss/vars";
@import "../../scss/mixins";

$white-color: #f5f5f5;
$base-color: #4f5253;
$brand-color: #4f9589;
$secondary-color: #fe6701;
$gutter-size: 32px;
$font-size: 15px;
$border-radius: 4px;

.WechaletSelect[class*="-container"] {
  font-family: "Poppins", sans-serif;
  display: block;
  position: relative;
  color: $base-color;
}

.WechaletSelect[class*="-container"]:focus {
  border-color: transparent !important;
  outline: none !important;
}

.WechaletSelect .WechaletSelect__control,
.WechaletSelect[class*="-container"] .WechaletSelect__control {
  border-radius: 2px !important;
  min-height: 50px;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.wc-footer-language .WechaletSelect .WechaletSelect__control,
.wc-footer-language
  .WechaletSelect[class*="-container"]
  .WechaletSelect__control {
  background-color: transparent;
  border-color: transparent;
}
.wc-footer-menu .WechaletSelect .WechaletSelect__menu {
  top: auto !important;
  bottom: 100% !important;
  position: absolute;
}
.wc-guest-row .WechaletSelect .WechaletSelect__control,
.wc-guest-row .WechaletSelect[class*="-container"] .WechaletSelect__control {
  background-color: #fff;
  border-color: #e8e8e8;
  margin-top: 15px;
  margin-bottom: 5px;
}
.WechaletSelect .WechaletSelect__control:hover,
.WechaletSelect[class*="-container"] .WechaletSelect__control:hover,
.WechaletSelect .WechaletSelect__control:focus,
.WechaletSelect[class*="-container"] .WechaletSelect__control:focus,
.WechaletSelect .WechaletSelect__control.WechaletSelect__control--is-focused,
.WechaletSelect .WechaletSelect__control.WechaletSelect__control--menu-is-open {
  border-radius: 2px !important;
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  box-shadow: none !important;
  outline: none !important;
}
.wc-guest-row .WechaletSelect .WechaletSelect__control:hover,
.wc-guest-row
  .WechaletSelect[class*="-container"]
  .WechaletSelect__control:hover,
.wc-guest-row .WechaletSelect .WechaletSelect__control:focus,
.wc-guest-row
  .WechaletSelect[class*="-container"]
  .WechaletSelect__control:focus,
.wc-guest-row
  .WechaletSelect
  .WechaletSelect__control.WechaletSelect__control--is-focused,
.wc-guest-row
  .WechaletSelect
  .WechaletSelect__control.WechaletSelect__control--menu-is-open {
  background-color: #fff !important;
  border-color: #e8e8e8 !important;
}
.WechaletSelect[class*="-container"] > span,
.WechaletSelect .WechaletSelect__control .WechaletSelect__indicator-separator {
  display: none !important;
}
.WechaletSelect .WechaletSelect__control .WechaletSelect__indicator {
  color: currentColor;
  -webkit-transition: all ease 400ms;
  transition: all ease 400ms;
}
.WechaletSelect
  .WechaletSelect__control.WechaletSelect__control--menu-is-open
  .WechaletSelect__indicator {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.wc-filters-dashboard
  .wc-search-expand
  > ul.uk-flex-inline
  > .wc-filter-item:last-child {
  display: none;
}
.WechaletSelect .WechaletSelect__menu,
.WechaletSelect__menu > .WechaletSelect__menu-list {
  margin: 0px !important;
  border: 0px !important;
  border-radius: 0px !important;
  padding: 0px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}
.WechaletSelect__menu > .WechaletSelect__menu-list > .WechaletSelect__option {
  padding: 12px 16px;
  color: #4f5253;
  font-size: 15px;
  width: 100%;
  font-weight: 500;
}
.WechaletSelect__menu
  > .WechaletSelect__menu-list
  > .WechaletSelect__option:hover,
.WechaletSelect__menu
  > .WechaletSelect__menu-list
  > .WechaletSelect__option:focus,
.WechaletSelect__menu
  > .WechaletSelect__menu-list
  > .WechaletSelect__option.WechaletSelect__option--is-focused,
.WechaletSelect__menu
  > .WechaletSelect__menu-list
  > .WechaletSelect__option.WechaletSelect__option--is-selected {
  background-color: #f5f5f5;
  color: currentColor;
  outline: none !important;
}
