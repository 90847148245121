@mixin reset-margin {
  margin: 0;
}

@mixin reset-box {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

@mixin reset-button {
  @include reset-box;

  appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin reset-field {
  @include reset-box;

  max-width: 100%;
  outline: none;

  &:focus {
    outline: none;
  }
}

@mixin reset-text-field {
  @include reset-field;

  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  &::-webkit-search-cancel-button {
    appearance: none;
  }

  &::placeholder {
    color: inherit;
    opacity: 1;
  }
}

@mixin reset-select {
  @include reset-field;

  appearance: none;
}
