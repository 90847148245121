@import "../../scss/reset";
@import "../../scss/vars";
@import "../../scss/mixins";

.WC_Button {
  display: inline-block;
  cursor: pointer;
  position: relative;
  appearance: none;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: $font-size;
  line-height: $line-height;
  text-transform: capitalize;
  color: $base-color;
  background-color: $white-color;
  border-width: 2px;
  border-style: solid;
  border-color: $base-color;
  border-radius: $border-radius;
  @include gutter();
  min-width: 150px;
  transition: 0.3s ease-in-out;
  transition-property: color, background-color, border-color;
  &.info {
    color: $white-color;
    background-color: $base-color;
    border-color: $base-color;
  }
  &.primary {
    color: $white-color;
    background-color: $brand-color;
    border-color: $brand-color;
  }
  &.secondary {
    color: $white-color;
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  &.outline {
    color: $base-color;
    background-color: transparent;
    border-color: $base-color;
  }
  &.outline.info {
    color: $base-color;
    background-color: transparent;
    border-color: $base-color;
  }
  &.outline.primary {
    color: $brand-color;
    background-color: transparent;
    border-color: $brand-color;
  }
  &.outline.secondary {
    color: $secondary-color;
    background-color: transparent;
    border-color: $secondary-color;
  }
  &[disabled],
  &.disabled {
    cursor: not-allowed;
  }
}
