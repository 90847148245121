@import 'styles/abstracts';

.wechalet {
  background-color: $gray-300;
  border-radius: 50%;
  display: inline-block;
  height: 1.25rem;
  margin-left: 5px;
  margin-right: 5px;
  transform: translateX(-15%);
  width: 25px;
}

.logo {
  max-width: 25px;

  @include screen-md {
    max-width: 50px;
  }
}
