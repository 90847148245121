@import '../../styles/abstracts';

.button {
  @include reset-button;

  align-items: stretch;
  border: 2px solid transparent;
  border-radius: $border-radius-base;
  cursor: pointer;
  display: inline-flex;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  justify-content: stretch;
  line-height: 1.5;
  min-height: $field-height-base;
  min-width: 150px;
  padding: $button-padding;
  position: relative;
  transition: 0.3s ease-in-out;
  transition-property: color, background-color, border-color, scale;

  &.loading {
    .content {
      opacity: 0;
    }
  }

  &.rounded {
    border-radius: 50px;
  }

  &:disabled {
    cursor: initial;
  }

  &.disabled {
    filter: grayscale(0.6);
    opacity: 0.8;
  }

  &.outlined {
    background-color: transparent;
  }

  &.block,
  &.flush {
    display: flex;
    width: 100%;
  }

  &.flush {
    border-radius: 0;
    min-height: 60px;
  }
}

.content,
.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.spaceBetween {
  justify-content: space-between;
}

.loader {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: min-content;
}

.small {
  border-width: 1px;
  font-size: 12px;
  min-height: 0;
  min-width: 0;
  padding: 7.5px 10px;

  &.circle-secondary {
    font-size: 20px;
    padding: 7.5px;
  }
}

.medium {
  border-width: 1px;
  font-size: 14px;
  min-height: 0;
  min-width: 0;
  padding: 9.5px 15px;

  &.circle-secondary {
    font-size: 24px;
    padding: 9.5px;
  }
}

.icon {
  display: flex;
  font-size: 18px;
  margin-right: 10px;
}

.shadow {
  &,
  &:hover,
  &:focus {
    box-shadow: 2px 4px 8px rgba($black, 0.25);
  }
}

.primary {
  background-color: $primary;
  border-color: $primary;
  color: $gray-100;

  &.outlined {
    color: $primary;
  }

  &:hover {
    background-color: $gray-100;
    border-color: $gray-100;
    color: $primary;
  }
}

.primarySolid {
  background-color: $primary;
  border-color: $primary;
  color: $gray-100;

  &.outlined {
    color: $primary;
  }

  &:hover {
    scale: 1.05;
  }
}

.primarySolidHardPaused {
  background-color: $primary-faded;
  border-color: $primary-faded;
  color: $gray-100;

  &.outlined {
    color: $primary;
  }

  &:hover {
    scale: 1.05;
  }
}

.primaryFaded {
  background-color: $primary-faded;
  border-color: $primary-faded;
  color: $gray-100;

  &.outlined {
    color: $primary;
  }

  &:hover {
    scale: 1.05;
  }
}


.secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;

  &.outlined {
    color: $secondary;
  }

  &:hover {
    background-color: rgba($secondary, 0.9);
    border-color: rgba($secondary, 0.9);
    color: rgba($white, 0.9);
  }
}

.circle-secondary {
  background-color: $secondary;
  border-color: $secondary;
  border-radius: 100%;
  color: $gray-100;

  &.outlined {
    color: $secondary;
  }
}

.roundedInfo,
.roundedInfoTransparent,
.roundedInfoBlack {
  background-color: $secondary-faded;
  border: 0 transparent solid;
  border-radius: 25px;
  color: $body-color;
  font-weight: $font-weight-medium;
  transition: background-color $transition-fast;

  &.medium {
    border-radius: 16px;
    padding: 5.5px 22px;
  }
}

@media screen and (max-width: 768px) {
  .roundedInfo,
  .roundedInfoTransparent,
  .roundedInfoBlack {
    &.medium {
      padding: 5px;
    }
  }
}

.roundedInfoTransparent {
  background-color: transparent;

  &:hover {
    background-color: $gray-100;
  }
}

.roundedInfoBlack {
  background-color: rgba($black, 0.45);
  color: $white;

  &:hover {
    background-color: rgba($black, 0.6);
  }
}

.info {
  background-color: $body-color;
  border-color: $body-color;
  color: $white;

  &.outlined {
    color: $body-color;
  }

  &:hover {
    background-color: rgba($body-color, 0.95);
    border-color: rgba($body-color, 0.95);
    color: $white;
  }
}

.infoSolid {
  background-color: $body-color;
  border-color: $body-color;
  color: $white;

  &.outlined {
    color: $body-color;
  }

  &:hover {
    background-color: rgba($body-color, 0.95);
    border-color: rgba($body-color, 0.95);
  }
}

.toggle {
  background-color: $body-color;
  border-color: $body-color;
  color: $white;

  &.outlined {
    color: $body-color;
  }
}

.google {
  background-color: $white;
  border-color: $body-color;
  color: $body-color;
}

.facebook {
  background-color: $facebook-color;
  border-color: $facebook-color;
  color: $white;

  &.outlined {
    color: $facebook-color;
  }
}

.link {
  color: $secondary;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
}

.link:hover {
  color: $body-color;
}
