//
// Breakpoints
//

$screen-xxs: 0;
$screen-xs: 360px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1440px;

//
// Container
//

$container-padding-base: 15px;
$container-padding-sm: 30px;
$container-padding-lg: 40px;
$container-padding-xl: 80px;

$container-content-box-width-xxs: 400px;
$container-content-box-width-xs: 600px;
$container-content-box-width-sm: 720px;
$container-content-box-width-md: 900px;
$container-content-box-width-lg: 1200px;
$container-content-box-width-xl: 1760px;

//
// Colors
//

// Grayscale

$white: #fff;
$black: #000;
$gray-100: #f5f5f5;
$gray-200: #e7e7e7;
$gray-250: #e4e4e4;
$gray-300: #cfcfcf;
$gray-400: #999;
$gray-500: #7b7b7b;
$gray-700: #4f5253;

// Named colors (avoid using in components)

$red: #dc3545;
$orange: #fe6701;
$orange-faded: #ff925c;
$yellow: #f90;
$green: #4f9589;
$green-faded: #edf4f3;

// Other brands

$facebook-color: #4568b2;

// Accents

$primary: $orange;
$primary-faded: $orange-faded;
$secondary: $green;
$secondary-faded: $green-faded;
$tertiary: $gray-700;

// States

$danger: $red;
$warning: $yellow;
$success: $green;
$info: $gray-700;

// Other

$body-color: $gray-700;
$body-bg: $white;

$border-color-base: $gray-200;

$link-color: $secondary;
$link-hover-color: $tertiary;

// Icons

$star-icon-color: #f5cb41;

//
// Typography
//

$font-family-base: 'Poppins', sans-serif;

$font-size-base: 15px;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.6;
$heading-line-height: 1.3;

$letter-spacing-base: 0;

//
// Transitions
//

$transition-fast-duration: 100ms;
$transition-fast: $transition-fast-duration ease-in-out;
$transition-base-duration: 200ms;
$transition-base: $transition-base-duration ease-in-out;
$transition-slow-duration: 400ms;
$transition-slow: $transition-slow-duration ease-in-out;

//
// Box properties
//

$border-radius-base: 4px;
$border-radius-small: 2px;
$border-radius-large: 15px;

//
// Spacing
//

$gap-small: 15px;
$gap-medium: 30px;

//
// Margins
//

$section-margin: 20px;

//
// Elements
//

$field-height-base: 50px;
$field-color-base: $body-color;
$field-placeholder-color-base: $gray-400;
$field-bg-color-base: $gray-100;
$field-border-width: 1px;
$field-border-color: transparent;
$field-border-color-focus: $secondary;
$field-border-color-invalid: $danger;
$field-border-base: $field-border-width solid $field-border-color;
$field-border-radius: $border-radius-small;
$field-padding-x: $gap-small;

$popup-menu-color-base: $body-color;
$popup-menu-bg-color: $white;
$popup-menu-border-radius: $border-radius-small;
$popup-menu-border-width: 1px;
$popup-menu-border-color: $gray-200;
$popup-menu-border-base: $popup-menu-border-width solid $popup-menu-border-color;
$popup-menu-padding: 24px 15px;
$popup-menu-option-color-focus: $body-color;
$popup-menu-option-bg-color-focus: $gray-100;
$popup-menu-option-color-selected: $white;
$popup-menu-option-bg-color-selected: $secondary;
$popup-menu-option-padding-x: $gap-small;
$popup-menu-option-padding-y: 12px;

$button-padding: 12px 30px;

//
// z-index list
//
// NOTE: most z-indexes should be added here to track them.
// Once the old css is removed, we can update the values to cleaner ones.

$z-index-dropdown: 11;
$z-index-footer: 999;
$z-index-pricing-box: 1000;
$z-index-search-filters: 10000;
$z-index-sticky-footer-actions: 9999910;
$z-index-mobile-full-map: 9999920;
$z-index-mobile-header: 9999930;
$z-index-map-wrapper: calc($z-index-mobile-header + 1);
$z-index-desktop-header: 9999935;
$z-index-mobile-menu: 9999940;
$z-index-mobile-navigation: 1000000;
$z-index-modal-fullscreen: 9999960;
$z-index-search-form: 9999960;
$z-index-navbar-hosting: 9999970;
$z-index-modal-box: 9999980;
$z-index-swal: 9999999;

//
// Nav
//

$mobile-nav-height: 65px;
$desktop-nav-height: 60px;

//
// Landing pages
//

$section-spacing-top-sm: 60px;
$section-spacing-bottom-sm: 95px;
$section-spacing-top-md: 125px;
$section-spacing-bottom-md: 190px;
