@import '../../styles/calendar-variables.module';

.container {
  align-items: center;
  border: solid $border-color-primary;
  border-width: 1px 1px 0;
  display: flex;
  flex: 1;
  max-width: 350px;

  .input {
    padding: 10px 10px 9px 48px !important;
  }
}
