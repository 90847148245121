$white-color: #f5f5f5;
$base-color: #4f5253;
$brand-color: #4f9589;
$secondary-color: #fe6701;
$gutter-size: 30px;
$line-height: 1.5;
$font-size: 15px;
$border-radius: 4px;

$calendar-width: 700px;

// Small tablets and large smartphones (landscape view)
$screen-xs: 576px;

// Small tablets (portrait view)
$screen-sm: 768px;

// Tablets and small desktops
$screen-md: 992px;

// Large tablets and desktops
$screen-lg: 1200px;