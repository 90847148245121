@import 'styles/abstracts';
@import '../../styles/calendar-variables.module';

.container {
  display: flex;
  height: 60px;
}

.day {
  align-items: center;
  display: flex;
  min-width: $day-md-width;
  padding: 0 15px;
  @include max-screen-md {
    min-width: $day-sm-width;
  }
}

.img {
  border-radius: 100px;
  height: 30px !important;
  margin-left: 10px;
  width: 34px !important;
  @include max-screen-md {
    display: none !important;
  }
}

.listingTitle {
  margin-left: 20px;
  @include max-screen-md {
    margin-left: 0;
  }
}

.heading {
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0 15px;

  &.title {
    border-color: $border-color-primary;
    border-style: solid;
    border-width: 0 1px 1px 0;
  }
}
