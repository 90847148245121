@import '../../styles/abstracts';

$icon-bounds: 40px;
$height: $field-height-base;
$border-width: $field-border-width;
$line-height: 28px;
$padding-x: $field-padding-x;
$icon-padding-x: 45px;
$padding-y: ($height - $line-height) * 0.5 - $border-width;

.wrapper {
  position: relative;
}

.button {
  @include reset-button;
  cursor: pointer;
}

.menu {
  background-color: $popup-menu-bg-color;
  border-radius: $popup-menu-border-radius;
  box-shadow: 2px 2px 8px rgba($black, 0.2);
  display: none;
  position: absolute;
  text-align: left;
  z-index: $z-index-dropdown;
}

%common {
  .button {
    background-color: $field-bg-color-base;
    border: $field-border-base;
    border-radius: $field-border-radius;
    color: $field-color-base;
    font-size: $font-size-base;
    line-height: $line-height;
    padding: $padding-y $icon-padding-x;
    position: relative;
    text-align: left;
    transition: $transition-base;
    transition-property: color, background-color, border-color;
    width: 100%;
  }

  .icon,
  .arrow {
    bottom: 0;
    display: flex;
    font-size: 20px;
    height: 20px;
    margin: auto;
    position: absolute;
    top: 0;
  }

  .icon {
    color: $secondary;
    left: $padding-x;
  }

  .arrow {
    right: $padding-x - 1px;
    transition: transform 0.3s;
  }

  .menu {
    border: $popup-menu-border-base;
    color: $popup-menu-color-base;
    left: 0;
    margin-bottom: 1px;
    margin-top: 1px;
    overflow: hidden;
    padding: 5px;
    top: 100%;
    width: 100%;
  }

  .menuItem {
    & + & {
      margin-top: 10px;
    }
  }

  .staticMenu {
    .menu {
      position: relative;
      top: auto;
    }
  }
}

.default {
  @extend %common;
}

.labelOnly {
  .button {
    background-color: inherit;
    color: inherit;
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    padding: 0;
  }

  .arrow {
    display: none;
  }

  .menu {
    border: 0;
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    padding: 0;
    top: 80px;
    width: 162px;
  }

  .menuItem {
    line-height: normal;
    margin: 0;
    transition: background-color $transition-base;
    width: 100%;

    &:hover {
      background-color: $gray-100;
    }
  }
}

.active {
  .menu {
    display: block;
  }

  .arrow {
    transform: rotate(180deg);
  }
}

.alignedLeftLabel {
  @extend %common;
}
