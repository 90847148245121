@mixin gutter($gutterSize: 12px 30px) {
  padding: $gutterSize;
}
@mixin mediaQuery($screen) {
  @media only screen and (max-width: $screen+"px") {
    @content;
  }
}

@mixin makeCheckbox($size: 18px) {
  /* Hide the browser's default checkbox */
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin-right: 24px;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: $size;
    width: $size;
    border-radius: 2px;
    border: 1px solid #4f5253;
    background-color: white;
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: $white-color;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: $base-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: $size * 0.5;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
