@import '../../styles/abstracts';

$line-height: 28px;
$icon-bounds-default: 40px;
$icon-bounds-banner: 48px;
$icon-bounds-filled: $icon-bounds-banner;

$padding-x: 16px;

.wrapper {
  font-size: $font-size-base;
}

@include overwrite {
  .label {
    @include form-label;
  }
}

.inputWrapper {
  position: relative;
}

.box {
  position: relative;
}

.clear {
  @include reset-button;

  cursor: pointer;
}

.icon,
.clear {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
}

.icon {
  pointer-events: none;
}

@include overwrite {
  .input {
    @include reset-text-field;
    color: $field-color-base;
    line-height: $line-height;
    text-size-adjust: none;
    width: 100%;
  }
}

// Themes

.default,
.compact {
  .icon,
  .clear {
    width: $icon-bounds-default;
  }

  .clear {
    color: $gray-400;
    right: 0;
  }
}

.default {
  .icon {
    color: $gray-400;
    right: 0;
  }

  .icon~.clear {
    right: $icon-bounds-default * 0.6;
  }

  .description {
    margin-top: 2px;
  }

  .hint {
    color: $gray-500;
    font-size: $font-size-base;
  }
}

.compact {
  .icon {
    color: $secondary;
    font-size: 24px;
    left: 0;
  }
}

.rounded {
  .icon {
    @include rounded-field-icon;
    height: 100%;

    left: auto;
    line-height: 1;
    right: 0;
    top: 0;

    svg * {
      vector-effect: non-scaling-stroke;
    }
  }

  .clear {
    right: 53px;
    width: 21px;
  }
}

@include overwrite {
  $border-width: $field-border-width;
  $padding-x: $field-padding-x;

  .default,
  .compact,
  .filled {
    .input {
      background-color: $field-bg-color-base;
      border: $field-border-base;
      border-radius: $field-border-radius;
      transition: $transition-base;
      transition-property: color, background-color, border-color;

      &::placeholder {
        color: $field-placeholder-color-base;
      }

      &:focus {
        border-color: $field-border-color-focus;
      }
    }

    &:global(.is-invalid) {
      .input {
        border-color: $field-border-color-invalid;
      }
    }
  }

  .default,
  .filled {
    $height: $field-height-base;
    $padding-y: (
      $height - $line-height) * 0.5 - $border-width;

    .input {
      padding: $padding-y $padding-x;
    }

    .icon~.input,
    .clear~.input {
      padding-right: $icon-bounds-default;
    }

    .icon~.clear~.input {
      padding-right: $icon-bounds-default * 1.4;
    }
  }

  .compact {
    $height: 42px;
    $padding-y: (
      $height - $line-height) * 0.5 - $border-width;

    .input {
      padding: $padding-y $padding-x;
    }

    .icon~.input {
      padding-left: $icon-bounds-default;
    }

    .clear~.input {
      padding-right: $icon-bounds-default;
    }
  }

  .rounded {
    .input {
      @include rounded-field;

      &::placeholder {
        @include rounded-field-placeholder;
      }
    }

    .input:focus {
      @include rounded-field-focus;

      .icon {
        border-color: transparent;
      }
    }

    .clear~.input {
      padding-right: 40px;
    }

    .icon~.input {
      padding-right: 53px;

      &:where(.clear ~ .input) {
        padding-right: 76px;
      }
    }
  }
}

.banner,
.fixedBanner {
  .icon,
  .clear {
    width: $icon-bounds-banner;
  }

  .icon {
    color: $secondary;
    font-size: 24px;
    left: 0;
  }

  .clear {
    color: $gray-300;
    font-size: 18px;
    right: 0;
  }
}

@include overwrite {
  .banner,
  .fixedBanner {
    .input {
      $height: 70px;
      $padding-x: 35px;
      $padding-y: (
        $height - $line-height) * 0.5;
      $background-color: $white;

      background-color: $background-color;
      border: 0 solid transparent;
      font-size: 16px;
      font-weight: $font-weight-medium;
      padding: $padding-y $padding-x;
      transition: $transition-base;
      transition-property: border-color;
    }

    .icon~.input {
      padding-left: $icon-bounds-banner;
    }

    .clear~.input {
      padding-right: $icon-bounds-banner;
    }
  }

  .fixedBanner {
    .input {
      background-color: $field-bg-color-base;
    }
  }

  .disabled {
    opacity: 0.5;

    .input {
      background-color: $gray-200;
    }
  }
}

.filled {
  .icon {
    background-color: $gray-700;
    border-color: $gray-700;
    border-radius: 0 2px 2px 0;
    color: #fff;
    flex-shrink: 0;
    font-size: 22px;
    padding: 0;
    right: 0;
    width: $icon-bounds-filled;
  }

  .clear {
    color: $gray-400;
    right: 0;
    width: $icon-bounds-filled;
  }

  .icon~.clear {
    right: $icon-bounds-filled;
  }
}

@include overwrite {
  .filled {
    .icon~.clear~.input {
      padding-right: $icon-bounds-filled + ($padding-x * 2
    );
    }
  }
}
