@import "../scss/reset";
@import "../scss/vars";
@import "../scss/mixins";
@import "../../styles/abstracts/";

.GuestBox_Container {
  font-family: "Poppins", sans-serif;
  display: inline-block;
  position: relative;
  color: $base-color;
}

.GuestBox_ToggleButton {
  cursor: pointer;
  margin: 0;
  outline: none;
  border: none;
  position: relative;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: normal;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color, border-color;
  padding: 14px 45px;
  background-color: $white-color;
  border-color: $white-color;
  color: $base-color;
}

.GuestBox_Container.ThemePrimary,
.GuestBox_Container.ThemeTertiary {
  display: block;
  width: 100%;

  .GuestBox_ToggleButton {
    width: 100%;
    text-align: left;
    border-radius: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .UserIcon {
      position: absolute;
      top: 50%;
      left: 15px;
      width: 24px;
      height: 24px;
      color: #4f9589;
      transform: translateY(-50%);
    }

    .ChevronIcon {
      position: absolute;
      top: 50%;
      right: 15px;
      height: 20px;
      width: 20px;
      transform: translateY(-50%);
      transition: 0.3s ease-in-out;
      transition-property: transform;
      transform-origin: center;

      &.rotate {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.GuestBox_Container.ThemeSecondary {
  display: inline-block;
  width: auto;
  margin-right: 12px;

  .GuestBox_ToggleButton {
    text-align: center;
    border-radius: 4px;
    display: inline-block;
    padding: 10px 18px;
    font-weight: 600;

    &:hover,
    &.isActive {
      border-color: $base-color;
      background-color: $base-color;
      color: $white-color;
    }

    .UserIcon {
      display: none;
    }

    .ChevronIcon {
      display: none;
    }
  }
}

.GuestBox_Container.ThemeTertiary {
  .GuestBox_ToggleButton {
    background: $white;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.GuestBox {
  position: absolute;
  padding: $popup-menu-padding;
  z-index: 1020;
  width: 100%;
  min-width: 320px;
  max-width: 360px;
  color: $base-color;
  background-color: $popup-menu-bg-color;
  @media screen and (min-width: 740px) {
    border: $popup-menu-border-base;
  }
  // box-shadow: 2px 2px 8px rgba($black, 0.2);
  margin-bottom: 1px;
  margin-top: 0px;

  @include mediaQuery(576) {
    min-width: 250px;
  }

  &.isStandalone {
    position: relative;
  }

  &_Row {
    display: flex;
    margin-bottom: $gutter-size;
    align-items: center;
    justify-content: space-between;
  }

  &_Row:last-child {
    margin-bottom: 0px;
  }

  &_Description {
    color: $brand-color;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }

  &_Label {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    transition: none;
    color: $base-color;
    line-height: 1.6;
  }

  &_Label>small {
    width: 100%;
    display: block;
  }

  &_Counter {
    display: inline-flex;
    vertical-align: middle;
    position: relative;
  }

  &_IncrementBtn,
  &_DecrementBtn,
  &_CountValue {
    outline: none;
    border: 1px solid $brand-color;
    background: transparent;
    padding: 12px 16px;
    width: 50px;
    height: 50px;
    font-size: 26px;
    line-height: 22px;
    color: $brand-color;
    box-sizing: border-box;
  }

  &_DecrementBtn {
    border-right: none;
    line-height: 0px;
    padding-bottom: 14px;
    cursor: pointer;
  }

  &_IncrementBtn {
    border-left: none;
    cursor: pointer;
  }

  &_IncrementBtn--disabled {
    cursor: not-allowed;
    color: $brand-color;
    border-color: $brand-color;
  }

  &_CountValue {
    font-size: 15px;
    line-height: 23px;
    color: $base-color;
    font-weight: 700;
    text-align: center;

    &:disabled {
      cursor: not-allowed;
      color: $brand-color;
      border-color: $brand-color;
    }
  }

  &_Apply,
  &_Cancel {
    padding: 0px;
    outline: none;
    appearance: none;
    border: none;
    font: inherit;
    background: none;
    font-size: 15px;
    color: $brand-color;
    font-weight: 500;
    cursor: pointer;
  }

  &_Cancel {
    color: $base-color;
  }

  &_Cancel:hover {
    color: darken($base-color, $amount: 10);
  }

  &_Apply:hover {
    color: darken($brand-color, $amount: 10);
  }

  /* Customize the label (the container) */
  &_RadioInput {
    font-weight: 500;
    color: lighten($base-color, 0%);
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    height: 26px;
    display: inline-flex;

    &.disabled {
      color: lighten($base-color, $amount: 0%);
      user-select: none;
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    }

    /* Create a custom radio button */
    input {
      position: absolute;
      cursor: pointer;
      left: 0px;
      width: 24px;
      height: 24px;
      border: 2px solid $brand-color;
      background-color: #ffffff;
      border-radius: 99px;
      -webkit-appearance: none;
      transition: all ease 0.3s;

      &:checked {
        border-width: 8px;
        border-color: $brand-color;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}