@import '../../styles/abstracts';

$prefix: ReactSelect;
$arrow-size: 20px;
$arrow-padding: 8px;

@function select($suffix) {
  @return ':global(.#{$prefix}__#{$suffix})';
}

.wrapper {
  &:global(.is-invalid) {
    .nativeSelect,
    .outer #{select(control)} {
      border-color: $danger;
    }

    .outer {
      &:hover {
        border-color: $danger;
      }

      &#{select(control--is-focused)} {
        border-color: rgba($danger, 0.5);
      }
    }
  }
}

.label {
  @include form-label;
}

.nativeWrapper {
  position: relative;
}

.nativeSelect {
  @include reset-select;

  padding-right: $arrow-size;
  width: 100%;
}

.arrow {
  bottom: 0;
  display: flex;
  font-size: $arrow-size;
  height: $arrow-size;
  margin: auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 1px;
  transition: transform 0.3s;
  width: $arrow-size;
}

.outer {
  color: $body-color;
  display: block;
  font-family: $font-family-base;

  #{select(control)} {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    height: auto;
    width: auto;
  }

  #{select(value-container)} {
    padding-left: $field-padding-x;
    padding-right: $field-padding-x;
  }

  #{select(single-value)},
  #{select(placeholder)} {
    color: inherit;
    margin: 0;
  }

  #{select(placeholder)} {
    color: $field-placeholder-color-base;
  }

  #{select(indicator)} {
    color: inherit;
    transition: transform $transition-slow;
  }

  #{select(indicator-separator)} {
    display: none;
  }

  #{select(menu)} {
    @include listbox;
  }

  #{select(menu-list)} {
    @include scrollbar;

    padding: 0;
  }

  #{select(option)} {
    @include listbox-item;
  }

  // States

  #{select(control--menu-is-open)} #{select(indicator)} {
    transform: rotate(-180deg);
  }

  #{select(option--is-focused)} {
    @include listbox-item--hover;

    &:active {
      background-color: $popup-menu-option-bg-color-focus;
    }
  }

  #{select(option--is-selected)} {
    @include listbox-item--selected;

    &:active {
      background-color: $popup-menu-option-bg-color-selected;
    }
  }

  #{select(option--is-selected)}#{select(option--is-focused)} {
    @include listbox-item--selected--hover;
  }
}

.default {
  .nativeSelect,
  .outer #{select(control)} {
    background-color: $field-bg-color-base;
    border: $field-border-base;
    border-radius: $field-border-radius;
    box-shadow: none;
    color: $field-color-base;
    font-size: $font-size-base;
    height: $field-height-base;
    transition: $transition-base;
    transition-property: color, background-color, border-color;
  }

  .nativeSelect:focus,
  .outer #{select(control--is-focused)} {
    border-color: $field-border-color-focus;
  }

  .nativeSelect {
    padding-left: $field-padding-x;
    padding-right: $arrow-size + $arrow-padding * 2;
  }

  .placeholder {
    color: $field-placeholder-color-base;

    option:disabled {
      color: $field-placeholder-color-base;
    }

    option {
      color: $field-color-base;
    }
  }

  .arrow {
    pointer-events: none;
    right: $arrow-padding;
  }

  .outer {
    #{select(control)} {
      &:hover {
        border-color: $field-border-color;
      }
    }

    #{select(control--is-focused)} {
      &:hover {
        border-color: $field-border-color-focus;
      }
    }

    #{select(control--menu-is-open)} #{select(indicator)} {
      border-color: $field-border-color-focus;
    }
  }
}

.inline {
  .nativeSelect,
  .outer {
    color: inherit;
  }

  .placeholder {
    color: $field-placeholder-color-base;

    option:disabled {
      color: $field-placeholder-color-base;
    }

    option {
      color: $field-color-base;
    }
  }

  .outer {
    margin: 0 -15px;
    padding: 0 15px;

    #{select(control)} {
      min-height: 0;
    }

    #{select(menu)} {
      margin-top: 12px;
    }

    #{select(value-container)} {
      padding: 0;
    }

    #{select(single-value)},
    #{select(placeholder)} {
      max-width: none;
      position: static;
      text-overflow: clip;
      transform: none;
    }

    #{select(dropdown-indicator)} {
      padding: 0;
    }

    #{select(menu)} {
      min-width: 100%;
      width: auto;
    }

    #{select(option--is-focused)} {
      background-color: rgba($popup-menu-option-bg-color-focus, 0.5);
      color: $popup-menu-option-color-focus;

      &:active {
        background-color: rgba($popup-menu-option-bg-color-focus, 0.5);
      }
    }

    #{select(option--is-selected)} {
      background-color: $popup-menu-option-bg-color-focus;
      color: $popup-menu-option-color-focus;

      &:active {
        background-color: $popup-menu-option-bg-color-focus;
      }
    }

    #{select(option--is-selected)}#{select(option--is-focused)} {
      background-color: darken($popup-menu-option-bg-color-focus, 5%);
    }
  }
}

.transparentBox {
  .nativeSelect,
  .outer #{select(control)} {
    background-color: transparent;
  }

  .outer {
    #{select(control)} {
      &:hover {
        background-color: $field-bg-color-base;
      }
    }
  }
}

.menuAlignRight {
  .outer {
    #{select(menu)} {
      right: 0;
    }
  }
}

:export {
  prefix: $prefix;
}
