@import 'styles/abstracts';

.pageTitle {
  font-size: 11px;
  margin-bottom: 25px;

  @include screen-md {
    font-size: 15px;
  }
}


.infoButton {
  border-radius: $border-radius-base;
}
