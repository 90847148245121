@import "~flag-icon-css/sass/flag-icon.scss";
@import "../scss/reset";
@import "../scss/vars";
@import "../scss/mixins";

.phone-input-container {
  width: 100%;
  display: flex;
  position: relative;
  background-color: $white-color;
  border-radius: 4px;
  .phone-input {
    width: calc(100% - 0px);
    font-size: 15px;
    color: #353535;
    letter-spacing: 0.5px;
    font-family: "Poppins", "sans-serif";
    border: 0px solid #e0e0e0;
    border-left-width: 1px;
    background-color: transparent;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  .hide-arrow {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
  // Country Selector
  .select-country {
    > div[class*="control"] {
      font-family: "Poppins", "sans-serif";
      width: 120px;
      border: none;
      cursor: pointer;
      border-radius: 0px;
      box-shadow: none;
      background-color: transparent;
      > div:not(:last-child) {
        padding: 10px;
        [class*="Input"] {
          opacity: 0;
          color: transparent;
        }
      }
      > div:last-child {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        [class*="Separator"] {
          display: none;
        }
        [class*="indicator"] {
          color: $base-color;
        }
      }
      [class*="singleValue"] {
        color: $base-color;
        font-size: 15px;
      }
    }
    > div[class*="menu"] {
      top: 100%;
      font-family: "Poppins", "sans-serif";
      color: $base-color;
      font-size: 14px;
      width: 100%;
      min-width: 120px;
      border-radius: 0px;
      padding: 0px;
      margin-top: 0px;
      box-shadow: 0px 2px 4px 2px hsla(0, 0%, 0%, 0.1);
      background-color: #fff;
      // List
      > div {
        padding: 0px;
        // List Item
        > div {
          // List Item Inner
          > span {
            display: inline-block;
            width: 100%;
          }
          &:hover,
          &:focus,
          &:active {
            background-color: $white-color;
            color: $base-color;
          }
        }
        // Scrollbar Chrome aand Safari Only
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
          box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
          background-color: $white-color;
        }
        &::-webkit-scrollbar {
          width: 6px;
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $base-color;
          border-radius: 10px;
        }
      }
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border: none;
      background-color: transparent;
      div[class*="control"] {
        box-shadow: none;
      }
    }
  }
}
// OPT/Code Input
.code-input {
  > .uk-inline {
    width: 100%;
    max-width: 250px;
    border-radius: 4px;
    overflow: hidden;
    @media only screen and (max-width: 560px) {
      max-width: 100%;
    }
  }
}
