@import "../scss/reset";
@import "../scss/vars";
@import "../scss/mixins";

.SelectableList_Container {
  font-family: "Poppins", sans-serif;
  display: inline-block;
  position: relative;
  color: $base-color;
  box-sizing: border-box;
  * {
    font-family: "Poppins", sans-serif;
    position: relative;
    box-sizing: border-box;
  }
}

.SelectableList_ToggleButton {
  cursor: pointer;
  margin: 0;
  outline: none;
  border: none;
  position: relative;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: normal;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color, border-color;
  @include gutter(10px 45px);
  padding-left: 16px;
  background-color: $white-color;
  border-color: $white-color;
  color: $base-color;
  max-width: 200px;
  font-weight: 600;
  text-align: left;
  border-radius: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover,
  &.isActive {
    background-color: $base-color;
    color: $white-color;
    svg {
      stroke: $white-color;
    }
  }
  .ChevronIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    height: 20px;
    width: 20px;
    transform: translateY(-50%);
    transition: 0.3s ease-in-out;
    transition-property: transform;
    transform-origin: center;
    &.rotate {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.SelectableList {
  position: relative;
  @include gutter(16px);
  z-index: 1020;
  width: 100%;
  min-width: 280px;
  max-width: 340px;
  background: #fff;
  color: $base-color;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
  &.isInsideDropdown {
    position: absolute;
  }

  &_Row {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    justify-content: space-between;
  }
  &_Row:last-child {
    margin-bottom: 0px;
  }

  &_ItemsContainer {
    display: block;
    flex: 1;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
  }
  &_ListItem {
    display: block;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e7e9ef;
    cursor: pointer;
  }
  &_ListItem:first-child {
    padding-top: 0px;
  }
  &_ListItem:last-child {
    padding-bottom: 12px;
  }

  &_ListItem > label {
    font-weight: normal;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
  }

  &_ListItem {
    @include makeCheckbox();
  }

  &_Apply,
  &_Cancel {
    padding: 0px;
    outline: none;
    appearance: none;
    border: none;
    font: inherit;
    background: none;
    font-size: 15px;
    color: $brand-color;
    font-weight: 500;
    cursor: pointer;
  }

  &_Cancel {
    color: $base-color;
  }
  &_Cancel:hover {
    color: darken($base-color, $amount: 10);
  }
  &_Apply:hover {
    color: darken($brand-color, $amount: 10);
  }
}
