@mixin scrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $gray-700;
  }
}
