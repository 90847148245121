@import "../scss/reset";
@import "../scss/vars";
@import "../scss/mixins";

.wc-booking-area {
  background-color: #ffffff;
}
.PricingBox {
  display: block;
  border: 1px solid #e7e9ef;
  background-color: #ffffff;
  max-width: 380px;
  min-width: 320px;
  padding: 20px;
  .PricingBox__Header {
    position: relative;
    padding-bottom: 12px;
    background-color: #ffffff;
    .wc-booking-price {
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: 1200px) {
    .DropdownDatePicker__content {
      padding-bottom: 84px;
    }
  }
  .uk-alert {
    @media only screen and (max-width: 1200px) {
      left: 0px;
      width: 100%;
      margin: 0px;
      z-index: 99999999;
    }
  }
  .text-bold {
    font-weight: bold;
  }
  .PricingRow {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: left;
  }
  .BookingInvoice__Row {
    text-align: left;
  }
  section {
    float: none;
  }
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__Params {
    > div {
      margin-bottom: 15px;
      position: relative;
    }
  }

  &__Footer {
    .booking-button {
      text-align: center !important;
      margin-top: 20px;
      button {
        width: 100%;
        margin: 0px 0px 15px 0px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        padding: 13px 30px;
      }
      small {
        font-size: 80%;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
      }
    }
    .share-button {
      cursor: pointer;
      background-color: #ffffff;
      border-color: #e7e9ef;
      display: inline-block;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      padding: 8px 15px;
      line-height: 30px;
      text-align: center;
      &__icon {
        display: inline-block;
        float: none;
        background-image: none;
        height: auto;
        width: auto;
        margin-right: 5px;
      }
    }
  }
}
.wc-booking-area.uk-active {
  .PricingBox__Header--devider {
    position: absolute;
    width: 120%;
    height: 1px;
    background-color: #e7e9ef;
    left: -20px;
    bottom: 0px;
  }
  .PricingBox .PricingBox__Body {
    margin-top: 20px;
  }
  .PricingBox .PricingBox__Header {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e7e9ef;
    border-top: 0px;
    &.uk-active {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
.wc-booking-checkout {
  .PricingBox__DatePicker {
    .WechaletDayPicker {
      right: 0px;
      left: auto;
      transform: translateX(0px);
    }
  }
}

.PricingBox__ExtraServices .PricingRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  span,
  label {
    font-family: "Poppins", "sans-serif";
    font-weight: normal;
    font-weight: 500;
    color: #4f5253;
    font-size: 15px;
  }
  .ExtraServiceLabel {
    @include makeCheckbox();
  }
}

.PricingBox hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  text-align: inherit;
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

.PricingBox__alert {
  font-size: 15px;
  padding: 6px 10px;
  display: inline-block;
  width: 100%;
  border-radius: 2px;
  background-color: #ff9900;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: center;
  font-family: "Poppins", "sans-serif";
  font-weight: normal;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.PricingBox__DatePicker--label.frozen,
.PricingBox__GuestPicker--label.frozen,
.PricingBox__ExtraService--label.frozen {
  font-family: "Poppins", sans-serif;
  color: #4f5253;
  font-size: 15px;
  align-items: center;
  display: flex;
  height: 24px;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    display: block;
  }
  .icon {
    margin-right: 6px;
    margin-left: 6px;
  }
  .icon:first-child {
    margin-right: 12px;
    margin-left: 0px;
  }
}
