@import 'styles/abstracts';
// Listing calendar variables

$day-height: 60px;
$day-md-width: 100px;
$day-sm-width: 50px;

$blocked-day-md-width: 10px;
$blocked-day-sm-width: 5px;

$listing-name-width-md: 350px;
$listing-name-width-sm: 100px;

// All z-index values should be listed here to keep track of them
$date-range-z-index: 1000002;
$legend-z-index: 1000003;
$status-select-z-index: 1000003;
$sidebar-container-z-index: 100001;
$elevated-day-z-index: 10;

// borders
$border-color-primary: $gray-250;
