.WechaletInvoice {
  font-family: "Poppins", sans-serif;
  color: #4f5253;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.6;
  background-color: #fff;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: white;
  box-sizing: border-box;
  min-width: 300px;
  width: 100%;
}

.WechaletInvoice hr.separator {
  box-sizing: content-box;
  overflow: visible;
  text-align: inherit;
  height: 0;
  margin: 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.WechaletInvoice hr.separator:last-child {
  border-top: 0px;
  margin: 0px;
}

.WechaletInvoice__Row {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.WechaletInvoice__Row:first-child {
  margin-top: 0px;
}

.text-bold {
  font-weight: 700;
}

.text-highlighted {
  color: #4f9589;
}

.WechaletInvoice_Svg,
.WechaletInvoice_Tooltip {
  vertical-align: middle;
  margin-left: 10px;
}
.WechaletInvoice_Tooltip {
  cursor: pointer;
}
.WechaletInvoice_Tooltip__triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #4f5253;
  z-index: 99999999;
}
