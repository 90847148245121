*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  // Replicate original theme behavior
  // Needed because UIKit is using rem units
  font-size: $font-size-base;
}

body {
  color: $body-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;


  @include max-screen-sm {
    // Replicate original responsive behavior
    // TODO: Remove this so responsive text size is not global
    font-size: 16px;
  }
}

img {
  max-width: 100%;
}

fieldset {
  @include reset-box;
}

legend {
  padding: 0;
}

blockquote {
  color: inherit;
  font-style: normal;
  margin: 0;
}
