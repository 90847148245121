:root {
  --gutter-size: 30px;
  --line-height: 15px;
  --font-size: 15px;
  --border-radius: 4px;
  --color-base: #4f5253;
  --color-white: #ffffff;
  --color-primary: #4f9589;
  --color-secondary: #fe6701;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.BookingActions_Container {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}

.BookingActions_Button {
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  display: block;
  flex: 1 1 100%;
  margin-right: 12px;
  text-align: center;
  text-transform: capitalize;

  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--color-base);
  background-color: var(--color-white);
  border: 2px solid var(--color-base);
  border-radius: var(--border-radius);
  transition: 0.3s ease-in-out;
  transition-property: color, background-color, border-color;
  line-height: 46px;
  padding: 0px 15px;
}

.BookingActions_Button:last-child {
  margin-right: 0px;
}

.BookingActions_Button:disabled,
.BookingActions_Button.disabled,
.BookingActions_Button.readonly {
  cursor: not-allowed;
}

.BookingActions_Button.primary {
  color: var(--color-white);
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.BookingActions_Button.secondary {
  color: var(--color-white);
  background-color: var(--color-secondary);
  border: 2px solid var(--color-secondary);
}

.BookingActions_Button.muted {
  color: var(--color-white);
  background-color: var(--color-base);
  border-color: var(--color-base);
}
