.floatingButton {
  align-items: center;
  background-color: #fe6700;
  border: 2px solid #fff;
  border-radius: 50%;
  bottom: 40px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 28px;
  height: 60px;
  justify-content: center;
  padding: 10px;
  position: fixed;
  right: 20px;
  width: 60px;
  z-index: 999999980;
}

.flowContainer {
  border: 2px dashed #4f9589;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  width: 100%;
}

.flowContainerPayment {
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;

  button {
    flex-grow: 1;
  }
}

.drawer {
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.1s ease-in-out;
  width: 300px;
  z-index: 999999999;
}

.drawerOpen {
  transform: translateX(0);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999990;
}

.overlayVisible {
  display: block;
}

.closeButton {
  background: none;
  border: 0;
  color: #4f9589;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.envSelect {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 60px 20px 0 !important;

}

@media (max-width: 768px) {
  .floatingButton {
    display: none;
  }
}

.drawerContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 60px 20px 20px;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.button {
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.primaryButton {
  background-color: #4f9589;
  color: #fff;

  &:hover {
    background-color: darken(#4f9589, 10%);
  }
}

.secondaryButton {
  background-color: #f0f0f0;
  color: #333;

  &:hover {
    background-color: darken(#f0f0f0, 10%);
  }
}

.cookiesConsentButton {
  margin-top: auto;
}
