@import '../../styles/abstracts';

$padding: 4px;
$button-size: $field-height-base - $padding * 2;
$border-width: $field-border-width;
$border-radius: $border-radius-base;

.default {
  background-color: $white;
  border-radius: $border-radius;
  display: flex;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  padding: $padding;
  transition: $transition-base;
  transition-property: box-shadow, background-color, color;
  width: min-content;
}

.focus {
  box-shadow: 0 0 0 2px rgba($secondary, 0.3);
}

.disabled {
  background-color: $gray-100;
  color: $gray-400;
}

.button {
  @include reset-button;
}

.value,
.button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.button {
  border-color: $secondary;
  border-style: solid;
  border-width: $border-width;
  color: $secondary;
  cursor: pointer;
  height: $button-size;
  transition: $transition-base;
  transition-property: background-color, color, border-color;
  width: $button-size;

  &:hover {
    background-color: $secondary;
    color: $white;
  }

  &:first-child {
    border-radius: $border-radius;
  }

  &:last-child {
    border-radius: $border-radius;
  }

  &:disabled {
    background-color: $gray-100;
    border-color: $gray-400;
    color: $gray-400;
    cursor: inherit;
  }
}

.value {
  height: $button-size;
  transition: border-color $transition-base;
  width: $button-size;
}
