@import 'styles/abstracts';
@import '../../styles/calendar-variables.module';

.menu {
  background-color: $white;
  border-radius: $border-radius-base;
  box-shadow: 0 0 16px 0 rgba($black, 0.2);
  opacity: 0;
  padding: 1rem;
  position: absolute;
  top: 60px;
  transform: translateY(5px);
  transition: visibility $transition-base, transform $transition-base,
    opacity $transition-base;
  visibility: hidden;
  width: 210px;
  // This is important to overlay the status dropdown below
  z-index: $legend-z-index;

  &.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  &.rightMost {
    right: 1px !important;
  }

  &.leftMost {
    left: -100px;
  }
}

.calendarKey {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include screen-md {
    justify-content: flex-start;
  }
}

.calendarKeyRow {
  align-items: center;
  display: flex;
  flex-basis: 100px;
  height: 25px;
  margin: 5px 0;
  margin-right: 20px;
  position: relative;

  @include screen-md {
    padding-right: 1rem;
  }
}

.calendarKeyLastRow {
  @include screen-md {
    border: 0;
  }
}

.calendarKeyText {
  margin: 0;
}

.explanation {
  height: 20px;
  margin-right: 1rem;
  transform: skew(-20deg);
  width: 50px;

  @include screen-md {
    width: 50px;
  }
}

.overlapped {
  background-color: $red;
}

.relative {
  position: relative;
}

.reserved {
  background-color: $green;
}

.blocked {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 50px;

  @include screen-md {
    width: 50px;
  }
}

.blockedItem {
  background-color: $gray-300;
  height: 100%;
  margin-right: 3px;
  width: 10px;

  @include screen-md {
    width: 20px;
  }
}

.legendPopoverContainer {
  display: flex;
}

.infoButton {
  color: unset;
  padding: 7.5px 10px;
}
