@import 'styles/abstracts';

.wrapper {
  align-items: center;
  background-color: $tertiary;
  bottom: 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: $z-index-swal;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }

}

.buttons {
  align-items: center;
  display: flex;
  gap: 1px;
  justify-content: flex-end;
  width: 400px;

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
  }

}

.link {
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 100%;

  :hover {
    color: #fe6601cb;
  }
}

.discription {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  width: 70%;


  @media screen and (max-width: 768px) {
    width: 100%;

  }
}
