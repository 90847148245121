@import '../../styles/abstracts';

.wrapper {
  align-items: center;
  color: $gray-700;
  display: flex;
  font-size: inherit;
}

.star {
  color: currentColor;
  display: flex;
  fill: currentColor;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
}

.rating {
  margin: 0 0 0 6px;
}

.count {
  margin: 0 0 0 3px;
}
