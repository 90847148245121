@import 'styles/abstracts';

// Base styles
.wrapper {
  display: flex;
  position: relative;
  row-gap: 10px;
}

.input {
  height: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.slider {
  align-items: center;
  background-color: $gray-100;
  border-radius: $border-radius-base;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  min-width: 100px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: fit-content;

  &::before {
    background-color: $secondary;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(100%);
    transition: transform $transition-base;
    width: 50%;
  }

  &:global(.is-checked) {
    &::before {
      transform: translateX(0);
    }

    .off {
      color: $gray-700;
    }

    .on {
      color: $white;
    }
  }

  &:global(.is-disabled) {
    cursor: initial;
    opacity: 0.5;
  }
}

.overlay {
  display: flex;
  font-size: 14px;
  justify-content: space-around;
  line-height: 20px;
  width: 100%;
}

.on,
.off {
  color: $gray-700;
  display: block;
  transition: color $transition-base;
  white-space: nowrap;
  z-index: 1;
}

.off {
  color: $white;
}

// Themes
.default {
  .wrapper {
    flex-direction: column;
  }

  .slider {
    height: 34px;
  }

  .on,
  .off {
    padding: 12px 10px;
  }
}

.banner {
  .wrapper {
    height: 100%;
  }

  .slider {
    &::before {
      background-color: $gray-700;
      transform: translateX(calc(100% - 22px));
      width: calc(50% + 12px);
    }

    &:global(.is-checked) {
      &::before {
        transform: translateX(0);
        width: calc(50%);
      }
    }
  }

  .on,
  .off {
    padding: 12px 24px;
  }
}
