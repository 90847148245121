@import "../scss/reset";
@import "../scss/vars";
@import "../scss/mixins";
@import '../../styles/abstracts';

$font-size: 13px;
$gutter-size: 20px;

.WechaletDayPicker {
  font-family: "Poppins", sans-serif;
  position: relative;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #ddd;
  @include max-screen-md {
    border: 0;
  }
  padding: 20px;
  z-index: 100000;

  .DayPicker-NavBar {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    padding: 24px;
    width: calc(100% - 0px);
    pointer-events: none;

    &--next,
    &--prev {
      cursor: pointer;
      pointer-events: all;
      background: none;
      border: none;
      display: block;
      color: $base-color;
      padding: 0;
      z-index: 9999;
      height: 20px;

      &:hover {
        color: $brand-color;
      }
    }

    &--next {
      float: right;
    }

    &--prev {
      float: left;
    }

    .DatesLoadingIndicator {
      display: none;
    }

    .ChevronIcon-left,
    .ChevronIcon-right {
      transform: rotate(180deg);
    }
  }

  .DayPicker-Months {
    width: calc(100% - 0px);
    float: left;

    .DayPicker-Month {
      width: calc(100% * 1 / 2 - 10px);
      float: left;
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }

      .DayPicker-Caption {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: $base-color;
      }
    }

    @media only screen and (max-width: 572px) {
      .DayPicker-Month {
        width: calc(100% - 0px);
        float: none;
      }
    }
  }

  & .DayPicker-Weekdays {
    margin-top: 12px;
  }

  & .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .DayPicker-Weekday {
    color: inherit;
    white-space: nowrap;
    cursor: pointer;
    font-size: $font-size;
    font-weight: normal;
    padding: 12px;
    flex: 1 1 32px;
    text-align: center;
    color: $base-color;

    abbr {
      text-decoration: none;
    }
  }

  & .DayPicker-Body {
    .DayPicker-Week {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .DayPicker-Day {
    font-size: $font-size;
    font-weight: normal;
    display: block;
    flex: 1 0 32px;
    text-align: center;
    padding: 0px 0px;
    color: $base-color;
    cursor: pointer;

    &:hover:not(.DayPicker-Day--start):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--minimum) {
      background-color: $white-color !important;
      color: currentcolor !important;
    }
  }

  .DayPicker-Day--disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: solid;

    &:not(.DayPicker-Day--outside) {
      background: repeating-linear-gradient(45deg,
          #ffffff,
          #ffffff 3px,
          #e5e5e5 3px,
          #e5e5e5 4px);
    }
  }

  .DayPicker-Day--minimum:not(.DayPicker-Day--outside) {
    color: #333 !important;
    background: #e6e5e5 !important;
    cursor: pointer;
  }

  .DayPicker-Day--firstAvailable:not(.DayPicker-Day--outside) {
    color: #333 !important;
    background: $white-color !important;
    cursor: pointer;
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    font-family: "Poppins", sans-serif;
    background-color: #ffffff;
    color: $base-color;
  }

  .DayPicker-Footer {
    font-family: "Poppins", sans-serif;
    width: 100%;
    padding: 15px 0px 0px;
    border-top: 0px;
    display: flex;
    justify-content: flex-end;

    .DayPicker-TodayButton {
      font-family: "Poppins", sans-serif;
      background-color: transparent;
      border: 0px;
      font-size: 14px;
      font-weight: normal;
      color: $brand-color;
      cursor: pointer;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--minimum):not(.DayPicker-Day--firstAvailable) {
    background-color: $white-color !important;
    color: currentcolor !important;
  }

  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #4f5253;
    color: white;
  }

  .DayPicker-Day--start:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--minimum):not(.DayPicker-Day--firstAvailable),
  .DayPicker-Day--end:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--minimum):not(.DayPicker-Day--firstAvailable) {
    background-color: #4f5253c9;
  }

  &--loading {
    .DayPicker-Body {
      opacity: 0.3;
      pointer-events: none;
    }

    .DayPicker-Day {
      // This prevents styles from not updating correctly after loading
      // on Safari 14.1.1
      transform: scale(1, 1);
    }

    .DayPicker-NavBar {
      .DatesLoadingIndicator {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.DropdownDatePicker {
  display: inline-block;
  width: $calendar-width;
  position: relative;

  .DropdownDatePicker__content {
    overflow-y: auto;
    position: absolute;
    left: 0px;
    top: 0px;
    right: auto;
    bottom: auto;
    transform: translate(0px, 49px);
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    z-index: 9999;
  }

  .DropdownDatePicker__content[data-show] {
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    left: 0px;
    top: 0px;
    right: auto;
    bottom: auto;
    transform: translate(0px, 49px);
  }

  // cannot set display to none because popper can't know the position of element with display:none
  .DropdownDatePicker__content:not([data-show]) {
    max-height: 1px;
    overflow: hidden;
  }

  .DropdownDatePicker__toggle {
    position: relative;
    cursor: pointer;
    margin: 0;
    outline: none;
    border: none;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: normal;
    transition-property: color, background-color, border-color;
    padding: 16px 16px;
    background-color: $white-color;
    border-color: $white-color;
    color: $base-color;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    border-radius: 2px;
    padding-left: 45px;
    display: block;
    width: $calendar-width;

    .CalendarIcon {
      position: absolute;
      top: 50%;
      left: 15px;
      width: 24px;
      height: 24px;
      color: $brand-color;
      transform: translateY(-50%);
    }

    .ChevronIcon {
      position: absolute;
      top: 50%;
      right: 15px;
      height: 20px;
      width: 20px;
      transform: translateY(-50%);
      transition: 0.3s ease-in-out;
      transition-property: transform;
      transform-origin: center;

      &.rotate {
        transform: translateY(-50%) rotate(180deg);
        transform-origin: center;
      }
    }
  }

  &--secondary {
    display: inline-block;
    width: auto;

    .DropdownDatePicker__toggle {
      display: inline-block;
      width: auto;
      text-align: center;
      border-radius: 4px;
      padding: 10px 18px;
      font-weight: 600;
      padding: 7px 15px;
      min-height: 38px;

      &:hover,
      &.isActive {
        border-color: $base-color;
        background-color: $base-color;
        color: $white-color;
      }

      .CalendarIcon {
        display: none;
      }

      .ChevronIcon {
        display: none;
      }
    }

    .DropdownDatePicker__content {
      top: 36px;
      left: 0px;
    }
  }

  &--search-filter {
    position: relative;
    display: block;
    width: 100%;

    .DropdownDatePicker__toggle {
      background-color: #fff;
      border-radius: 0 !important;
      padding: 24px 16px 24px 48px;
      font-weight: 500;
      border: 0 solid #f5f5f5;
      border-right: none;
      border-left: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 70px;
      width: 100%;
    }
  }

  &.isFullWidth {
    display: block;
    width: 100%;
    position: relative;

    .DropdownDatePicker__toggle {
      display: block;
      width: 100%;
      margin: 0;
    }
  }
}

// mobile css rules
@media only screen and (max-width: 576px) {
  .DropdownDatePicker--search-filter {
    .WechaletDayPicker {

      .DayPicker-Weekday,
      .DayPicker-Day {
        flex: 1 1 32px;
        padding: 5px;
      }
    }
  }

  .DropdownDatePicker--secondary {
    .WechaletDayPicker {

      .DayPicker-Weekday,
      .DayPicker-Day {
        flex: 1 1 32px;
        padding: 5px;
      }
    }
  }
}

// tablet
@media only screen and (max-width: 767px) {
  .DropdownDatePicker {
    .WechaletDayPicker {
      .DayPicker-Months {
        .DayPicker-Month {
          @media (max-width: 767px) {
            min-width: 300px;
            margin-right: 0px;

            &:last-child {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .DropdownDatePicker--search-filter {
    .WechaletDayPicker {
      width: 100%;

      .DayPicker-Months {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .DayPicker-Month {
          width: 300px;
          max-width: 100%;
          margin-right: 0px;
          margin-bottom: 20px;
        }
      }
    }

    .DropdownDatePicker__toggle {
      background-color: #f5f5f5;
    }
  }

  .DropdownDatePicker--secondary {
    .WechaletDayPicker {
      top: 100%;
      width: 100%;

      .DayPicker-Months {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .DayPicker-Month {
          width: 300px;
          max-width: 100%;
          margin-right: 0px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.SingleDayPicker {
  font-family: "Poppins", sans-serif;
  position: relative;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  z-index: 100000;
  top: 100%;
  width: 100%;

  .DayPicker-NavBar {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    padding: 24px;
    width: calc(100% - 0px);
    pointer-events: none;

    &--next,
    &--prev {
      cursor: pointer;
      pointer-events: all;
      background: none;
      border: none;
      display: block;
      color: $base-color;
      padding: 0;
      z-index: 9999;
      height: 20px;

      &:hover {
        color: $brand-color;
      }
    }

    &--next {
      float: right;
    }

    &--prev {
      float: left;
    }

    .DatesLoadingIndicator {
      display: none;
    }

    .ChevronIcon-left,
    .ChevronIcon-right {
      transform: rotate(180deg);
    }
  }

  .DayPicker-Months {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;


    .DayPicker-Month {
      float: left;
      width: 300px;
      max-width: 100%;
      margin-right: 0px;
      margin-bottom: 20px;

      &:last-child {
        margin-right: 0px;
      }

      .DayPicker-Caption {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: $base-color;
      }
    }

    @media only screen and (max-width: 572px) {
      .DayPicker-Month {
        width: calc(100% - 0px);
        float: none;
      }
    }
  }

  & .DayPicker-Weekdays {
    margin-top: 12px;
  }

  & .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .DayPicker-Weekday {
    color: inherit;
    white-space: nowrap;
    cursor: pointer;
    font-size: $font-size;
    font-weight: normal;
    padding: 12px;
    flex: 1 1 32px;
    text-align: center;
    color: $base-color;

    abbr {
      text-decoration: none;
    }
  }

  & .DayPicker-Body {
    .DayPicker-Week {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .DayPicker-Day {
    font-size: $font-size;
    font-weight: normal;
    display: block;
    flex: 1 1 32px;
    text-align: center;
    padding: 10px 12px;
    color: $base-color;
    cursor: pointer;

    &:hover:not(.DayPicker-Day--start):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--minimum) {
      background-color: $white-color !important;
      color: currentcolor !important;
    }
  }

  .DayPicker-Day--disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: solid;

    &:not(.DayPicker-Day--outside) {
      background: repeating-linear-gradient(45deg,
          #ffffff,
          #ffffff 3px,
          #e5e5e5 3px,
          #e5e5e5 4px);
    }
  }

  .DayPicker-Day--minimum:not(.DayPicker-Day--outside) {
    color: #333 !important;
    background: #e6e5e5 !important;
    cursor: pointer;
  }

  .DayPicker-Day--firstAvailable:not(.DayPicker-Day--outside) {
    color: #333 !important;
    background: $white-color !important;
    cursor: pointer;
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    font-family: "Poppins", sans-serif;
    background-color: #ffffff;
    color: $base-color;
  }

  .DayPicker-Footer {
    font-family: "Poppins", sans-serif;
    width: 100%;
    padding: 15px 0px 0px;
    border-top: 0px;
    display: flex;
    justify-content: flex-end;

    .DayPicker-TodayButton {
      font-family: "Poppins", sans-serif;
      background-color: transparent;
      border: 0px;
      font-size: 14px;
      font-weight: normal;
      color: $brand-color;
      cursor: pointer;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--minimum):not(.DayPicker-Day--firstAvailable) {
    background-color: $white-color !important;
    color: currentcolor !important;
  }

  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $brand-color;
    color: white;
  }

  .DayPicker-Day--start:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--minimum):not(.DayPicker-Day--firstAvailable),
  .DayPicker-Day--end:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--minimum):not(.DayPicker-Day--firstAvailable) {
    background-color: darken($brand-color, 0.3);
  }

  &--loading {
    .DayPicker-Body {
      opacity: 0.3;
      pointer-events: none;
    }

    .DayPicker-Day {
      // This prevents styles from not updating correctly after loading
      // on Safari 14.1.1
      transform: scale(1, 1);
    }

    .DayPicker-NavBar {
      .DatesLoadingIndicator {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.SingleDropdownDatePicker {
  height: 50px;
}
