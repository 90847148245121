@mixin listbox {
  background-color: $popup-menu-bg-color;
  border: $popup-menu-border-base;
  border-radius: $popup-menu-border-radius;
  box-shadow: 2px 2px 8px rgba($black, 0.2);
  color: $popup-menu-color-base;
  font-size: $font-size-base;
  margin-bottom: 1px;
  margin-top: 1px;
  overflow: hidden;
  text-align: left;
}

@mixin listbox-item {
  cursor: default;
  overflow: hidden;
  padding: $popup-menu-option-padding-y $popup-menu-option-padding-x;
  text-overflow: ellipsis;
  transition: $transition-fast;
  transition-property: background-color, color;
  white-space: nowrap;
}

@mixin listbox-item--selected {
  background-color: $popup-menu-option-bg-color-selected;
  color: $popup-menu-option-color-selected;
}

@mixin listbox-item--hover {
  background-color: $popup-menu-option-bg-color-focus;
  color: $popup-menu-option-color-focus;
}

@mixin listbox-item--selected--hover {
  background-color: rgba($popup-menu-option-bg-color-selected, 0.8);
  color: $popup-menu-option-color-selected;
}
