@import '../../styles/abstracts/';

.wrapper {
  &:global(.has-icon) {
    .label {
      &::before {
        content: none;
      }
    }
  }

  &:global(.is-invalid) {
    .label {
      &::before {
        border-color: $danger;
        border-width: 2px;
      }
    }
  }

  &:global(.is-small) {
    .text {
      font-size: 12px;
    }
  }
}

.button {
  .label {
    align-items: center;
  }

  .text {
    background-color: $gray-100;
    border-radius: $border-radius-small;
    font-size: inherit;
    font-weight: $font-weight-semi-bold;
    margin-top: 0;
    padding: 8px 10px;
    transition: $transition-base;
    transition-property: background-color, color;
  }

  .input:checked {
    +.label {
      &::before {
        background-color: $tertiary;
        border-color: $tertiary;
      }

      .text {
        background-color: $secondary;
        color: $white;
      }
    }
  }
}

.input {
  @include sr-only;

  &:checked:disabled {
    + .label {
      color: $gray-700;

      &::before {
        background-color: $gray-700;
        border-color: $gray-700;
        color: $white;
      }
    }
  }

  &:checked {
    +.label {
      &::before {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
      }
    }
  }
}

.label {
  cursor: pointer;
  display: flex;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;

  &::before {
    border: 1px solid $tertiary;
    border-radius: $border-radius-small;
    color: transparent;
    content: '\f00c';
    display: block;
    flex-shrink: 0;
    font-family: FontAwesome;
    font-size: 16px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    transition: $transition-base;
    transition-property: background-color, border-color, color;
    width: 20px;
  }
}

.icon {
  flex-shrink: 0;
  font-size: 26px;
  line-height: 1;
}

.text {
  flex: 0 1 auto;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 1px;
}
