@import 'styles/abstracts';

.container {
  margin-left: 1rem;

  @include max-screen-md {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  label > span > span {
    font-weight: $font-weight-bold;
    @include max-screen-md {
      padding: 4px 14px !important;
    }

  }
}
