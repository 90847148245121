@import '../scss/vars';

.fullWidth {
  width: 100%;
}

.containedWidth {
  width: $calendar-width;
}

.lower-z-index {
  z-index: 0;
}

.dayStyle {
  display: flex;
  flex-direction: column;
  padding: 5px;

  span {
    &:only-child {
      padding: 7px;

      // if not mobile
      @media (max-width: $screen-xs) {
        padding: 0;
      }
    }

    &:nth-child(2) {
      font-size: 9px;
    }
  }
}

.selectedDayPrice {
  span {
    &:nth-child(2) {
      color: #fe6701;
    }
  }
}
