.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 15px;
}

.noMarginTop {
  margin-top: 0;
}
