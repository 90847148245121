@mixin rounded-field {
  border: 1px solid $gray-200;
  border-radius: 16px;
  color: $gray-700;
  font-size: 14px;
  font-weight: $font-weight-medium;
  line-height: 21px;
  padding: 4.5px 10px;
  transition: $transition-base;
  transition-property: background-color, border, color;
}

@mixin rounded-field-placeholder {
  color: $gray-500;
}

@mixin rounded-field-focus {
  background-color: $secondary-faded;
  border-color: $secondary-faded;
}

@mixin rounded-field-icon {
  background-color: $gray-200;
  border-radius: 32px;
  color: $body-color;
  font-size: 18px;
  height: 32px;
  transition: $transition-base;
  transition-property: color, background-color;
  width: 48px;
}
