@import '../../styles/calendar-variables.module';

.container {
  align-items: center;
  border: solid $border-color-primary;
  border-width: 1px 1px 0;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: $listing-name-width-md;
  min-height: 70px;
  z-index: $elevated-day-z-index;
}
