@import 'styles/abstracts';
@import '@design-library/DropdownMenu/DropdownMenu.module';
@import '../../styles/calendar-variables.module';

.checkbox {
  margin-bottom: 5px;
}

.container {
  z-index: $status-select-z-index;

  button {
    padding: 5px 40px 5px 15px !important;
  }

  > div > div {
    width: unset !important;
  }

  > div > div > ul {
    align-items: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    /* stylelint-disable-next-line selector-max-compound-selectors */
    > li > button {
      padding: 0 !important;
    }
  }
}

