@import 'styles/abstracts';
@import '../../../styles/calendar-variables.module';

.blocked {
  align-self: flex-start;
  display: flex;
  flex: 1;
  justify-content: space-around;
  left: 50%;
  pointer-events: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%) skew(-20deg);
}

.reserved {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.stripe {
  background-color: $gray-300;
  display: inline-block;
  min-width: $blocked-day-sm-width;
  min-width: $blocked-day-md-width;
  padding: 0.7rem 0;
  pointer-events: none;
  transform: scaleY(1.2);

  @include max-screen-md {
    width: 10px;
  }
}

.reservedBlocked {
  align-items: center;
  display: flex;
  flex: 1;
}

.reservedBlockedStart {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.reservedEnd {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.labelContainer {
  align-items: center;
  color: $white;
  display: flex;
  font-size: 0.85rem;
  position: absolute;
  text-transform: capitalize;
  white-space: nowrap;

  img {
    background-color: #fff;
    border: solid 1px #fff;
    border-radius: 99px;
    height: 28px;
    margin-left: 5px;
    margin-right: 5px;
    max-height: 28px;
    max-width: 28px;
    object-fit: cover;
    width: 28px;
  }
}

.guestContainer {
  display: flex;
  flex-direction: column;
  font-size: 0.65rem;
}

.freeDayContainer {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 10px;
  position: relative;
}

.priceTagContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 10px;
}

.noteImageIcon {
  height: 12px;
  transform: translateY(25%);
  width: 12px;

  @include screen-md {
    height: 18px;
    width: 18px;
  }
}

.noteContainer {
  bottom: 10px;
  left: 5px;
  position: absolute;
}

.elevated {
  z-index: $elevated-day-z-index;
}

.overlappedBack {
  left: 0;
  margin-left: 0 !important;
  position: absolute;
  top: 23%;
  width: 100%;
  z-index: inherit;
}
