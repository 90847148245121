@import 'styles/abstracts';
@import '../../styles/calendar-variables.module';

.container {
  align-items: center;
  background-color: transparent;
  display: flex;
  font-family: inherit;
  font-size: 15px;
  font-weight: 500;
  text-align: end;

  @include max-screen-md {
    font-size: 11px;
  }

  &.weekend {
    font-weight: bold;
  }
}
