@use 'sass:math';

@import 'styles/abstracts';

$shine-size: 60px;
$animation-duration: 1.5s; // pixel per seconds

@keyframes shine {
  from {
    transform: translateX(-$shine-size);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes fade-flash {
  from {
    background-color: $gray-200;
  }

  to {
    background-color: rgba($gray-200, 0.4);
  }
}

.element {
  animation: fade-flash math.div($animation-duration, 2) alternate infinite ease-in-out;
  background-color: $gray-200;
  border-radius: 15px;
  display: inline-block;
  height: 1em;
  mask-image: radial-gradient($white, $black); // Safari border radius hack
  max-width: 100%;
  overflow: hidden;
  width: 100%;

  &::before {
    animation: shine $animation-duration infinite linear;
    background: linear-gradient(90deg, rgba($white, 0) 0, rgba($white, 0.75) math.div($shine-size, 2), rgba($white, 0) $shine-size);
    content: '';
    display: block;
    height: 100%;
    min-width: $shine-size;
    transform: translateX(#{-$shine-size});
    width: 100%;
  }
}
